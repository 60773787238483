import { graphql, StaticQuery } from "gatsby"
import React from "react"
import "../layouts/base-styles.scss"
import "../layouts/LayoutCommon"
import { baseClass, childClass } from "../layouts/LayoutCommon";
import { MdxConnection, Mdx } from "@GraphQLModels";
import SiteHeader from "components/headers/SiteHeader";
import { Pedal, PedalMaker, PedalCategory } from "@ViewModels";
import PedalCardList from "components/pedal-browser/PedalCardList";
import MdxBlock from "components/texty/MdxBlock";
import { PedalBrowserGrid, PedalBrowserNavigator, PedalBrowserContent, leadInBlockClassName } from "components/pedal-browser/PedalBrowser";
import SiteFooter from "components/footer/SiteFooter";
import PageMeta from "components/texty/PageMeta";

interface IProps {
  data: {
    mdx: Mdx;
    allMdx: MdxConnection;
    trendingPedals: MdxConnection;
  }
}

export default function BrowsePedals(props: IProps) {
  const data = props.data;
  const trendingPedals = data.trendingPedals.edges.map(x => Pedal.fromMdxNode(x.node));
  const makers = data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'maker').map(x => PedalMaker.fromMdxNode(x.node));
  const categories = data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'category').map(x => PedalCategory.fromMdxNode(x.node));
  return (
    <div className={baseClass}>
      <PageMeta
        title='Browse Pedals'
        route='/browse-pedals'>
      </PageMeta>
      <SiteHeader />

      <PedalBrowserGrid className={childClass('container')} noTitleBar={true}>
        <MdxBlock mdx={data.mdx} showTitle={true} className={leadInBlockClassName} />
        <PedalBrowserNavigator categories={categories} makers={makers} />
        <PedalBrowserContent>
          <h3 className={childClass('section-seperator-title')}>Trending Pedals</h3>
          <PedalCardList pedals={trendingPedals} className={childClass('row')} />
        </PedalBrowserContent>
      </PedalBrowserGrid>
      <SiteFooter />
    </div >
  )
}

export const pageQuery = graphql`
  query BrowsePedalsQuery {
              mdx(fields: {route: {eq: "/browse-pedals-page/" } }) {
              id
        frontmatter {
              title
          mainImage
            }
        code {
              body
            }
            }
      trendingPedals: allMdx( filter: {frontmatter: {trending: {eq: true } } } ) {
              edges {
            node {
              fields {
            route
          }
            frontmatter {
              title
            }
            }
          }
        }
      allMdx( filter: {frontmatter: {templateKey: { in: [ "maker", "category" ] } } } ) {
              edges {
            node {
              fields {
            route
          }
            frontmatter {
              templateKey
              mainImage
            title
          }
        }
      }
    }
}
`
